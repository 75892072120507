import Vue from 'vue'

import { defaultParams, couponMaxAge, couponErrorMessages } from '@/utils/constants'

export const state = () => ({
  lines: [],
  dispatchDay: null,
  deliveryDateFrom: null,
  deliveryDateTo: null,
  cutoff: null,
  recommendations: [],
  discount: 0,
  upgradePaths: [],
  basicProductSwitcherMappings: [],
  productSwitcherMappings: [],
  hasRetrievedMappings: false,
})

export const getters = {
  totalPrice(state) {
    const basketTotal = state.lines.reduce((total, line) => {
      const { originalUnitPrice, unitPrice, qty } = line
      const itemTotal = unitPrice
        ? parseFloat(unitPrice) * parseInt(qty, 10)
        : parseFloat(originalUnitPrice) * parseInt(qty, 10)
      return total + itemTotal
    }, 0)

    return basketTotal - parseFloat(state.discount)
  },
  totalQuantity(state) {
    return state.lines.reduce((quantity, line) => quantity + parseInt(line.qty, 10), 0)
  },
  totalSave(state) {
    return state.lines.reduce((total, line) => {
      const { originalUnitPrice, unitPrice, qty } = line
      const priceDifference = unitPrice ? parseFloat(originalUnitPrice) - parseFloat(unitPrice) : 0
      const savedAmount = priceDifference * parseInt(qty, 10)

      return total + savedAmount
    }, 0)
  },
  upgradePathById: (state) => (id) => {
    return state.upgradePaths.find((upgradePath) => upgradePath.id === id)
  },
}

export const mutations = {
  SET_BASKET_LINES(state, lines) {
    Vue.set(state, 'lines', [...lines])
  },
  ADD_ITEM(state, item) {
    const itemToUpdateIndex = state.lines.findIndex(
      (line) => line.productId === item.productId && JSON.stringify(line.options) === JSON.stringify(item.options),
    )
    const itemExist = itemToUpdateIndex > -1
    const { qty } = item
    const quantity = itemExist ? state.lines[itemToUpdateIndex].qty + qty : qty
    const updatedItem = { ...item, qty: quantity }

    if (itemExist) {
      Vue.set(state.lines, itemToUpdateIndex, updatedItem)
    } else {
      Vue.set(state, 'lines', [...state.lines, updatedItem])
    }
  },
  UPDATE_ITEM_QUANTITY(state, { item, quantity }) {
    const itemToUpdateIndex = state.lines.findIndex(
      (line) => line.productId === item.productId && JSON.stringify(line.options) === JSON.stringify(item.options),
    )

    if (itemToUpdateIndex > -1) {
      if (quantity <= 0) {
        state.lines.splice(itemToUpdateIndex, 1)
      } else {
        const updatedItem = { ...item, qty: quantity }
        Vue.set(state.lines, itemToUpdateIndex, updatedItem)
      }
    }
  },
  REPLACE_ITEM(state, { fromItem, toItem }) {
    const itemToReplaceIndex = state.lines.findIndex(
      (line) =>
        line.productId === fromItem.productId && JSON.stringify(line.options) === JSON.stringify(fromItem.options),
    )
    if (itemToReplaceIndex > -1) {
      toItem.qty = 1
      Vue.set(state.lines, itemToReplaceIndex, toItem)
    }
  },
  REPLACE_ITEM_VIA_PRODUCT_SWITCHER(state, { fromItem, toItem }) {
    const itemToReplaceIndex = state.lines.findIndex((line) => {
      const lineOptionsString = JSON.stringify(line.options.map((option) => option.id))
      const fromItemOptionsString = JSON.stringify(fromItem.optionIds)

      return line.productId === fromItem.productId && lineOptionsString === fromItemOptionsString
    })
    if (itemToReplaceIndex > -1) {
      Vue.set(state.lines, itemToReplaceIndex, toItem)
    }
  },
  SET_GUESSTIMATION(state, guesstimation) {
    Vue.set(state, 'dispatchDay', guesstimation.dispatchDay)
    Vue.set(state, 'deliveryDateFrom', guesstimation.deliveryDateFrom)
    Vue.set(state, 'deliveryDateTo', guesstimation.deliveryDateTo)
    Vue.set(state, 'cutoff', guesstimation.cutoff)
  },
  SET_RECOMMENDATIONS(state, recommendations) {
    Vue.set(state, 'recommendations', recommendations)
  },
  SET_DISCOUNT(state, discount) {
    Vue.set(state, 'discount', discount)
  },
  RESET_GUESSTIMATION(state) {
    Vue.set(state, 'dispatchDay', null)
    Vue.set(state, 'deliveryDateFrom', null)
    Vue.set(state, 'deliveryDateTo', null)
    Vue.set(state, 'cutoff', null)
  },
  SET_UPGRADE_PATH(state, { id, upgradePath }) {
    const itemToUpdateIndex = state.upgradePaths.findIndex((upgradePath) => upgradePath.id === id)
    const itemExist = itemToUpdateIndex > -1

    if (itemExist) {
      Vue.set(state.upgradePaths, itemToUpdateIndex, { id, upgradePath })
    } else {
      Vue.set(state, 'upgradePaths', [...state.upgradePaths, { id, upgradePath }])
    }
  },
  SET_BASIC_PRODUCT_SWITCHER_MAPPINGS(state, basicProductSwitcherMappings) {
    Vue.set(state, 'basicProductSwitcherMappings', basicProductSwitcherMappings)
  },
  CLEAR_BASIC_PRODUCT_SWITCHER_MAPPINGS(state) {
    Vue.set(state, 'basicProductSwitcherMappings', [])
  },
  SET_PRODUCT_SWITCHER_MAPPINGS(state, productSwitcherMappings) {
    Vue.set(state, 'productSwitcherMappings', productSwitcherMappings)
  },
  CLEAR_PRODUCT_SWITCHER_MAPPINGS(state) {
    Vue.set(state, 'productSwitcherMappings', [])
  },
  UPDATE_PRODUCT_SWITCHER_ITEM_QUANTITY(state, { item, quantity }) {
    const itemOptionIds = item.options.map((option) => option.id)
    const itemToUpdateIndex = state.productSwitcherMappings.findIndex((mapping) => {
      return (
        (item.productId === mapping.from.productId &&
          JSON.stringify(itemOptionIds) === JSON.stringify(mapping.from.optionIds)) ||
        (item.productId === mapping.to.productId &&
          JSON.stringify(itemOptionIds) === JSON.stringify(mapping.to.optionIds))
      )
    })

    if (itemToUpdateIndex > -1) {
      if (quantity <= 0) {
        state.productSwitcherMappings.splice(itemToUpdateIndex, 1)
      } else {
        const updatedItemFrom = { ...state.productSwitcherMappings[itemToUpdateIndex].from, qty: quantity }
        const updatedItemTo = { ...state.productSwitcherMappings[itemToUpdateIndex].to, qty: quantity }
        Vue.set(state.productSwitcherMappings, itemToUpdateIndex, { from: updatedItemFrom, to: updatedItemTo })
      }
    }
  },
  REMOVE_PRODUCT_SWITCHER_MAPPINGS_BY_TARGET_PRODUCT_ID(state, targetProductId) {
    const mappingsWithoutMatchingTargetProductId = state.productSwitcherMappings.filter((mapping) => {
      return mapping.to.productId !== targetProductId
    })
    Vue.set(state, 'productSwitcherMappings', mappingsWithoutMatchingTargetProductId)
  },
  SET_HAS_RETRIEVED_MAPPINGS(state, retrievalState) {
    Vue.set(state, 'hasRetrievedMappings', retrievalState)
  },
}

export const actions = {
  async addToBasket({ dispatch, rootGetters, rootState, commit }) {
    dispatch('prescription/validateEmptyOptions', null, { root: true })

    if (rootGetters['prescription/isValid']) {
      const { product, prescription } = rootState
      const isAccessory = product.options.length === 0
      const { leftEye, rightEye } = rootGetters['prescription/optionsStrings']
      const itemsOptions = []

      if (isAccessory) {
        itemsOptions.push([])
      }

      if (leftEye) {
        itemsOptions.push(prescription.leftEyeOptions)
      }

      if (rightEye) {
        itemsOptions.push(prescription.rightEyeOptions)
      }

      itemsOptions.forEach((itemOptions) => {
        commit('ADD_ITEM', {
          name: product.name,
          packSize: product.packSize,
          qty: prescription.quantity,
          productId: parseInt(product.id, 10),
          options: product.options.map((option) => {
            const { value, label } = itemOptions[option.name]

            return {
              id: value,
              type: option.name,
              value: label,
            }
          }),
          image: [product.images[0]] || null,
          originalUnitPrice: product.fullPrice,
          unitPrice: product.discountedPrice,
          code: product.code,
          link: product.link,
        })
      })

      await dispatch('saveBasket')
    }
  },
  async addRecommendedToBasket({ commit, dispatch }, recommendation) {
    const { name, productId, images, price, discountPrice, productCode: code, link } = recommendation
    const item = {
      name,
      packSize: '',
      qty: 1,
      productId: parseInt(productId, 10),
      options: [],
      image: images[0] || null,
      originalUnitPrice: price,
      unitPrice: discountPrice,
      code,
      link,
    }

    commit('ADD_ITEM', item)
    await dispatch('saveBasket')
  },
  async updateQuantity({ commit, dispatch }, { item, quantity }) {
    commit('UPDATE_ITEM_QUANTITY', { item, quantity })
    dispatch('updateProductSwitcherMappingItemQuantity', { item, quantity })
    await dispatch('saveBasket')
  },
  async loadLocalBasket({ dispatch }) {
    const lines = await JSON.parse(localStorage.getItem('basket'))

    if (lines?.length) {
      await dispatch('loadBasket', lines)
      await dispatch('getDiscountedBasket')
    }
  },
  async loadBasket({ commit, dispatch }, lines) {
    const extendedLines = await dispatch('extendLinesWithUpgradePath', lines)
    commit('SET_BASKET_LINES', extendedLines)
    await Promise.all([dispatch('getGuesstimation'), dispatch('getRecommendations')])
  },
  async extendLinesWithUpgradePath({ dispatch }, lines) {
    const deduplicatedProductIds = new Set(lines.map((line) => line.productId))

    const productUpgradePathMapping = await Promise.all(
      [...deduplicatedProductIds].map(async (productId) => {
        const upgradePath = await dispatch('getUpgradePathForProductId', productId)
        return upgradePath
      }),
    )

    lines.forEach((line) => {
      line.upgradePath = productUpgradePathMapping.find((mapping) => line.productId === mapping.id)?.upgradePath || null
    })
    return lines
  },
  async getUpgradePathForProductId({ commit, getters }, productId) {
    let upgradePath = getters.upgradePathById(productId)
    if (upgradePath) {
      return upgradePath
    }
    try {
      const response = await this.$axios.get(`/products/${productId}`, { params: defaultParams })
      const { data } = response.data
      upgradePath = data.upgradePath || null
    } catch {
      upgradePath = null
    }

    commit('SET_UPGRADE_PATH', { id: productId, upgradePath })
    return { id: productId, upgradePath }
  },
  async emptyBasket({ commit }) {
    commit('SET_BASKET_LINES', [])
    commit('SET_DISCOUNT', 0)
    commit('RESET_GUESSTIMATION')
    commit('CLEAR_PRODUCT_SWITCHER_MAPPINGS')
    await localStorage.setItem('basket', JSON.stringify([]))
  },
  async saveBasket({ state, rootState, dispatch }) {
    const { orderId } = rootState.order.order

    await localStorage.setItem('basket', JSON.stringify(state.lines))

    if (orderId && rootState.auth.loggedIn) {
      await dispatch('order/updateOrder', { orderContext: 'store/basket saveBasket' }, { root: true })
    } else {
      await await dispatch('getDiscountedBasket')
    }

    if (state.lines.length) {
      if (!this.$auth.loggedIn) {
        await Promise.all([dispatch('getGuesstimation'), dispatch('getRecommendations')])
      }
    } else {
      dispatch('order/setAutoReorderDaysInterval', '', { root: true })
      dispatch('order/setNextOrderDate', '', { root: true })
    }
  },
  async getDiscountedBasket({ state, commit, rootState, dispatch }) {
    const { couponCode } = rootState
    const basket = state.lines.map((item) => {
      const { options, productId, qty } = item
      const option = options.reduce((string, option) => `${string}${option.id}|`, `${productId}:`).slice(0, -1)
      return { option, qty }
    })

    if (!basket.length) {
      return
    }

    commit('REMOVE_ERROR', 'basket', { root: true })
    commit('ADD_LOADING', 'basket', { root: true })

    try {
      const params = { basket: JSON.stringify(basket), ...(couponCode && { couponCode }), ...defaultParams }
      const response = await this.$axios.get('/order/basket', { params })
      const { data } = response.data

      const extendedLines = await dispatch('extendLinesWithUpgradePath', data.lines)
      commit('SET_BASKET_LINES', extendedLines)

      if (data.coupon) {
        commit('SET_COUPON_DETAILS', data.coupon, { root: true })
      }

      commit('SET_DISCOUNT', data.discount)

      await dispatch('getRecommendations')
      if (couponCode) {
        this.$cookies.set('couponCode', couponCode, { maxAge: couponMaxAge })
      }
    } catch (error) {
      // Only set coupon error if the errorCode is a coupon error.
      if (couponCode && couponErrorMessages[error.response?.data?.errorCode]) {
        const errorMessage = couponErrorMessages[error.response.data.errorCode]

        commit('SET_COUPON_ERROR', { couponCode, errorMessage }, { root: true })
      }

      try {
        const params = { basket: JSON.stringify(basket), ...defaultParams }
        const response = await this.$axios.get('/order/basket', { params })
        const { data } = response.data

        const extendedLines = await dispatch('extendLinesWithUpgradePath', data.lines)
        commit('SET_BASKET_LINES', extendedLines)
        commit('SET_DISCOUNT', data.discount)
        await dispatch('getRecommendations')
      } catch (error) {
        commit('ADD_ERROR', { id: 'basket', error }, { root: true })
      }
    }

    commit('REMOVE_LOADING', 'basket', { root: true })
  },
  async getGuesstimation({ state, commit, rootState }) {
    const options = state.lines.map((item) => {
      const { options, productId, qty } = item
      const option = options.reduce((string, option) => `${string}${option.id}|`, `${productId}:`).slice(0, -1)
      return { option, qty }
    })

    commit('REMOVE_ERROR', 'basketGuesstimation', { root: true })
    commit('ADD_LOADING', 'basketGuesstimation', { root: true })

    try {
      const { orderId } = rootState.order.order
      let orderParams = {}
      if (orderId && rootState.auth.loggedIn) {
        orderParams = { calledFrom: 'cart', orderId }
      }
      const params = { options: JSON.stringify(options), ...orderParams, ...defaultParams }
      const response = await this.$axios.get('/stock/guesstimation', { params })
      const { data: guesstimation } = response.data
      const deserializedGuesstimation = {
        dispatchDate: guesstimation.estimatedDispatchDay,
        deliveryDateFrom: guesstimation.estimatedDelivery.from,
        deliveryDateTo: guesstimation.estimatedDelivery.to,
        cutoff: guesstimation.cutoff,
      }

      commit('SET_GUESSTIMATION', deserializedGuesstimation)
    } catch (error) {
      commit('ADD_ERROR', { id: 'basketGuesstimation', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'basketGuesstimation', { root: true })
  },
  async getRecommendations({ commit, state, rootState }) {
    const { couponCode } = rootState
    const productIds = [...new Set(state.lines.map((item) => item.productId))].join(',')
    const params = { productIds, ...(couponCode && { couponCode }), ...defaultParams }

    commit('REMOVE_ERROR', 'basketRecommendations', { root: true })
    commit('ADD_LOADING', 'basketRecommendations', { root: true })

    try {
      const response = await this.$axios.get('/products/recommendations', { params })
      const { data: recommendations } = response.data
      const deserializedRecommendations = recommendations.map((recommendation) => {
        const [image] = recommendation.images
        return { ...recommendation, image: image || null }
      })

      commit('SET_RECOMMENDATIONS', deserializedRecommendations)
    } catch (error) {
      commit('ADD_ERROR', { id: 'basketRecommendations', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'basketRecommendations', { root: true })
  },
  async upgradeItem({ dispatch }, { fromItem, toItem }) {
    const options = await dispatch('getUpgradeItemOptions', { fromItem, toItem })
    if (!options) {
      return
    }

    const replacementItem = { ...toItem, options, productId: toItem.id }
    await dispatch('replaceLineItem', { fromItem, toItem: replacementItem })
  },
  async getUpgradeItemOptions(_context, { fromItem, toItem }) {
    const sourceOptionsIds = JSON.stringify(fromItem.options.map((option) => option.id))
    try {
      const params = {
        sourceOptionsIds,
        ...defaultParams,
      }
      const response = await this.$axios.get(`/products/${fromItem.productId}/packsizeToggleOptions/${toItem.id}`, {
        params,
      })
      const { data: options } = response.data
      return options
    } catch {
      // Fail silently for MVP - if packsizeToggleOptions endpoint returns error response, we just return undefined
    }
  },
  async replaceLineItem({ commit, dispatch }, { fromItem, toItem }) {
    commit('REPLACE_ITEM', { fromItem, toItem })
    await dispatch('saveBasket')

    // If productIds match, we have edited a line item prescription.
    if (fromItem.productId === toItem.productId) {
      // Clear any product switcher mappings where target product matches prescription edited product
      commit('REMOVE_PRODUCT_SWITCHER_MAPPINGS_BY_TARGET_PRODUCT_ID', toItem.productId)
    }
    await dispatch('refreshProductSwitcherMappings')
  },
  replaceLineItemViaProductSwitcher({ commit }, { fromItem, toItem }) {
    commit('REPLACE_ITEM_VIA_PRODUCT_SWITCHER', { fromItem, toItem })
  },
  async retrieveBasicProductSwitcherMappings({ commit, state }) {
    const basket = state.lines.map((line) => ({
      productId: line.productId,
      options: line.options.map((option) => option.id),
    }))
    try {
      const response = await this.$axios.post(
        '/order/basket/product-switch-recommendations',
        { basket },
        { params: { ...defaultParams } },
      )

      const data = response.data.data.map((item) => {
        const basketItem = basket.find((basketItem) => basketItem.productId === item.to?.productId)

        if (basketItem !== undefined) {
          this.$log({
            name: 'ProductSwitchIgnored',
            properties: {
              message: 'Product switch line ignored as both source and target is in the basket',
            },
          })
          item.to = null
        }

        return item
      })

      commit('SET_BASIC_PRODUCT_SWITCHER_MAPPINGS', data)
    } catch (error) {
      commit('CLEAR_BASIC_PRODUCT_SWITCHER_MAPPINGS')
      this.$log({
        name: 'ProductSwitcherError',
        properties: {
          reason: 'error in retrieveBasicProductSwitcherMappings',
          message: error.message,
          error,
        },
      })
    }
  },
  async getFullMappingLineFromBasketLine({ state, dispatch }, line) {
    const lineOptionIds = line.options.map((option) => option.id)
    const basicMappingItem = state.basicProductSwitcherMappings.find((item) => {
      return (
        line.productId === item.from.productId && JSON.stringify(lineOptionIds) === JSON.stringify(item.from.options)
      )
    })
    // Throws error on failure to retrieve product data
    const toProductData = await dispatch('getProductDataById', basicMappingItem.to?.productId)

    const optionsArray = []
    toProductData.options.forEach((option) => {
      const matchingOption = option.items.find((optionItem) => {
        return basicMappingItem.to.options.includes(optionItem.value)
      })
      optionsArray.push({
        type: option.name,
        id: matchingOption.value,
        value: matchingOption.label,
      })
    })
    return {
      from: {
        productId: line.productId,
        name: line.name,
        options: line.options,
        optionIds: line.options.map((option) => option.id),
        unitPrice: line.unitPrice,
        originalUnitPrice: line.originalUnitPrice,
        qty: line.qty,
        image: line.image,
        packSize: line.packSize,
      },
      to: {
        productId: basicMappingItem.to.productId,
        name: toProductData.name,
        optionIds: basicMappingItem.to.options,
        options: optionsArray,
        unitPrice: toProductData.discountPrice,
        originalUnitPrice: toProductData.price,
        qty: line.qty,
        image: toProductData.images.length ? toProductData.images[0] : null,
        packSize: toProductData.packSize,
      },
      copy: basicMappingItem.copy,
    }
  },
  async getProductSwitcherMappings({ state, commit, dispatch }) {
    await dispatch('retrieveBasicProductSwitcherMappings')

    if (!state.basicProductSwitcherMappings.length) {
      commit('CLEAR_PRODUCT_SWITCHER_MAPPINGS')
      return
    }
    const switchableProductIds = getSwitchableProductIds(state.basicProductSwitcherMappings)
    if (!switchableProductIds.length) {
      commit('CLEAR_BASIC_PRODUCT_SWITCHER_MAPPINGS')
      commit('CLEAR_PRODUCT_SWITCHER_MAPPINGS')
      return
    }

    const basketLinesThatAreSwitchable = state.lines.filter((line) => switchableProductIds.includes(line.productId))

    const fullMappings = []
    const productIdsNotRetrieved = []
    for await (const line of basketLinesThatAreSwitchable) {
      try {
        const fullMappingLine = await dispatch('getFullMappingLineFromBasketLine', line)
        fullMappings.push(fullMappingLine)
      } catch (error) {
        if (error.cause?.productId) {
          productIdsNotRetrieved.push(error.cause.productId)
        }
      }
    }
    // Filter out any mappings where product data could not be retrieved for that productId
    const fullMappingsWithData = fullMappings.filter((mapping) => {
      return !productIdsNotRetrieved.includes(mapping.to.productId)
    })
    commit('SET_PRODUCT_SWITCHER_MAPPINGS', fullMappingsWithData)
    commit('SET_HAS_RETRIEVED_MAPPINGS', true)

    // Clear the temporary basicProductSwitcherMappings as they've done their job
    commit('CLEAR_BASIC_PRODUCT_SWITCHER_MAPPINGS')
  },
  // Refresh the productSwitcherMappings when line item productIds change.
  async refreshProductSwitcherMappings({ state, dispatch }) {
    // Only refresh if we have already retrieved mappings
    if (!state.hasRetrievedMappings) {
      return
    }

    await dispatch('getProductSwitcherMappings')
  },
  async getProductDataById({ rootState }, productId) {
    const { couponCode } = rootState
    const params = { ...(couponCode && { couponCode }), ...defaultParams }
    try {
      const response = await this.$axios.get(`/products/${productId}`, { params, useCache: true })
      const { data } = response.data
      return data
    } catch {
      throw new Error(`Could not retrieve product data for productId ${productId}`, {
        cause: {
          productId,
        },
      })
    }
  },
  async switchProductLines({ state, dispatch }, { fromProductId, reverse }) {
    state.productSwitcherMappings.forEach((mapping) => {
      if (mapping.from.productId === fromProductId) {
        const fromItem = reverse ? mapping.to : mapping.from
        const toItem = reverse ? mapping.from : mapping.to
        dispatch('replaceLineItemViaProductSwitcher', { fromItem, toItem })
      }
    })
    await dispatch('saveBasket')
  },
  updateProductSwitcherMappingItemQuantity({ commit }, { item, quantity }) {
    commit('UPDATE_PRODUCT_SWITCHER_ITEM_QUANTITY', { item, quantity })
  },

  async updateProductSwitcherMappingsPrices({ commit, state, dispatch }) {
    if (!state.productSwitcherMappings.length) {
      return
    }
    const updatedProductSwitcherMappings = []
    // Track any mappings where we could not retrieve product data
    const fromProductIdsForIrretrievableMappings = []

    for await (const mapping of state.productSwitcherMappings) {
      try {
        const fromProductData = await dispatch('getProductDataById', mapping.from.productId)
        const toProductData = await dispatch('getProductDataById', mapping.to.productId)
        const fromPricing = {
          originalUnitPrice: fromProductData.price,
          unitPrice: fromProductData.discountPrice,
        }
        const toPricing = {
          originalUnitPrice: toProductData.price,
          unitPrice: toProductData.discountPrice,
        }
        const updatedFromItem = { ...mapping.from, ...fromPricing }
        const updatedToItem = { ...mapping.to, ...toPricing }
        const updatedMapping = { from: updatedFromItem, to: updatedToItem }
        updatedProductSwitcherMappings.push(updatedMapping)
      } catch (error) {
        fromProductIdsForIrretrievableMappings.push(mapping.from.productId)
      }
    }

    // Remove mappings where we failed to retrieve product data for at least one mapping with the same from productId
    const filteredUpdatedProductSwitcherMappings = updatedProductSwitcherMappings.filter((mapping) => {
      return !fromProductIdsForIrretrievableMappings.includes(mapping.from.productId)
    })

    commit('SET_PRODUCT_SWITCHER_MAPPINGS', filteredUpdatedProductSwitcherMappings)
  },
}

function getSwitchableProductIds(mappings) {
  const fromProductIds = mappings.map((mapping) => mapping.from.productId)
  let deduplicatedFromProductIds = [...new Set(fromProductIds)]
  mappings.forEach((productMapping) => {
    if (!productMapping.to) {
      deduplicatedFromProductIds = deduplicatedFromProductIds.filter(
        (productId) => productId !== productMapping.from.productId,
      )
    }
  })
  return deduplicatedFromProductIds
}
