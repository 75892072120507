import Vue from 'vue'

import { defaultParams } from '@/utils/constants'

export const state = () => ({
  storedCards: [],
})
export const getters = {
  getCardById: (state) => (id) => {
    return state.storedCards.find((card) => card.id.toString() === id.toString())
  },
}
export const mutations = {
  SET_STOREDCARDS(state, storedCards) {
    Vue.set(state, 'storedCards', storedCards)
  },
}
export const actions = {
  async getAllStoredCards(
    { commit },
    { withExpiredCards = true, paymentGatewayCodes = ['braintree_credit_cards', 'braintree_billing_agreements'] } = {},
  ) {
    if (paymentGatewayCodes.length === 0) {
      commit('SET_STOREDCARDS', [])
      return
    }

    commit('ADD_LOADING', 'storedCards', { root: true })

    const params = {
      withExpiredCards,
      paymentGatewayCodes: paymentGatewayCodes.join(','),
      ...defaultParams,
    }
    const response = await this.$axios.get('/payments/cards', { params })
    const { data: storedCards } = response.data

    commit('SET_STOREDCARDS', storedCards)
    commit('REMOVE_LOADING', 'storedCards', { root: true })
  },
  async deleteStoredCard({ commit, state, getters }, id) {
    await this.$axios.delete(`/payments/cards/${id.toString()}`, { params: defaultParams })
    setTimeout(
      () =>
        commit(
          'SET_STOREDCARDS',
          state.storedCards.filter((card) => card.id.toString() !== id.toString()),
        ),
      200,
    )
  },
  async makeDefaultPaymentMethod({ commit }, cardId) {
    commit('REMOVE_ERROR', 'defaultPaymentMethod', { root: true })
    commit('ADD_LOADING', 'storedCards', { root: true })

    const params = { ...defaultParams }
    try {
      await this.$axios.put(
        '/auth/user/defaultPaymentMethod',
        { defaultPaymentMethodId: cardId },
        {
          headers: {
            Authorization: this.$auth.strategy.token.get(),
          },
          params,
        },
      )
    } catch (error) {
      commit('ADD_ERROR', { id: 'defaultPaymentMethod', error }, { root: true })
    }
    commit('REMOVE_LOADING', 'storedCards', { root: true })
  },
}
