import Vue from 'vue'
import { splitTreatments } from '@/utils/constants'

export const state = () => ({
  showKlarnaSplit: {
    retrieved: false,
    treatment: false,
  },
  autoReorderReauthenticateCards: {
    retrieved: false,
    treatment: false,
  },
  myAccountAutoReorderEnabled: {
    retrieved: false,
    treatment: false,
  },
  collapsedDeliveryAccordionReorder: {
    retrieved: false,
    treatment: false,
  },
  collapsedDeliveryAccordionCheckout: {
    retrieved: false,
    treatment: false,
  },
  collapsedBasketAccordionReorder: {
    retrieved: false,
    treatment: false,
  },
  collapsedBasketAccordionCheckout: {
    retrieved: false,
    treatment: false,
  },
  autoReorderSetup: {
    retrieved: false,
    treatment: false,
  },
  paymentApplePay: {
    retrieved: false,
    treatment: false,
  },
  paymentGooglePay: {
    retrieved: false,
    treatment: false,
  },
  paymentGooglePayTesting: {
    retrieved: false,
    treatment: false,
  },
  paymentPaypalExpress: {
    retrieved: false,
    treatment: false,
  },
  paymentBraintreeCards: {
    retrieved: false,
    treatment: false,
  },
  paymentShowManagement: {
    retrieved: false,
    treatment: false,
  },
  paymentBraintreeVaulted: {
    retrieved: false,
    treatment: false,
  },
  paymentBraintreeVaultedBillingAgreement: {
    retrieved: false,
    treatment: false,
  },
  reorderCTANavigateToReviewAndPaySplit: {
    retrieved: false,
    treatment: false,
  },
  onlineCancellationLink: {
    retrieved: false,
    treatment: false,
  },
  paymentPageBasketItemLinks: {
    retrieved: false,
    treatment: false,
  },
  studentDiscountShowInFooterMenu: {
    retrieved: false,
    treatment: false,
  },
  liveChatGenesys: {
    retrieved: false,
    treatment: false,
  },
  uspBannerVisible: {
    retrieved: false,
    treatment: false,
  },
  productPageUpsell: {
    retrieved: false,
    treatment: false,
  },
  showLoggedOutFreeShippingThreshold: {
    retrieved: false,
    treatment: false,
  },
  productPageShowPricePerLens: {
    retrieved: false,
    treatment: false,
  },
  basketPageEditPrescription: {
    retrieved: false,
    treatment: false,
  },
  productPagePackSizeSwitcher: {
    retrieved: false,
    treatment: false,
  },
  paymentPageEditPrescription: {
    retrieved: false,
    treatment: false,
  },
  orderHistoryTrackingStatus: {
    retrieved: false,
    treatment: false,
  },
  orderSummaryBasketItemLinks: {
    retrieved: false,
    treatment: false,
  },
  paymentAllowVaultingApplePay: {
    retrieved: false,
    treatment: false,
  },
  paymentAllowVaultingKlarna: {
    retrieved: false,
    treatment: false,
  },
  paymentProductSwitcher: {
    retrieved: false,
    treatment: false,
  },
  autoReorderSetupVariant: {
    retrieved: false,
    treatment: false,
  },
  paymentAllowVaultingGooglePay: {
    retrieved: false,
    treatment: false,
  },
  paymentShowKlarnaStored: {
    retrieved: false,
    treatment: false,
  },
})

export const mutations = {
  SET_SPLIT_TREATMENT(state, { splitName, treatment }) {
    Vue.set(state, splitName, { retrieved: true, treatment })
  },
}

export const actions = {
  async getSplitTreatment({ commit, dispatch, state }, splitName) {
    if (!state[splitName] || !splitTreatments[splitName]) {
      return false
    }
    if (!state[splitName].retrieved) {
      const treatmentValue = await dispatch('retrieveTreatmentFromSplit', { splitName })
      const treatment = treatmentValue === splitTreatments[splitName].show
      /*
       * paymentGooglePay is an exceptional Split which doesn't use just the show/hide model
       * If the treatment value is 'testing', we also set the paymentGooglePayTesting treatment value to true
       */
      if (splitName === 'paymentGooglePay') {
        commit('SET_SPLIT_TREATMENT', {
          splitName: 'paymentGooglePayTesting',
          treatment: treatmentValue === splitTreatments.paymentGooglePay.testing,
        })
      }
      commit('SET_SPLIT_TREATMENT', { splitName, treatment })
    }
    return state[splitName].treatment
  },
  async getTreatmentWithoutStoring({ dispatch }, { splitName, attributes = {} }) {
    if (!this.$splitClient || !splitTreatments[splitName]) {
      return false
    }
    const treatmentValue = await dispatch('retrieveTreatmentFromSplit', { splitName, attributes })
    return treatmentValue === splitTreatments[splitName].show
  },
  async retrieveTreatmentFromSplit(_context, { splitName, attributes = {} }) {
    if (!this.$splitClient) {
      return getFallbackTreatment(splitTreatments[splitName])
    }

    try {
      await this.$splitClient.ready()
      const treatmentValue = this.$splitClient.getTreatment(splitTreatments[splitName].treatmentName, attributes)
      return treatmentValue
    } catch (error) {
      const userId = this.$cookies.get('ls_lb_uid')
      const authorizationKey = this.$splitFactory?.settings?.core?.authorizationKey

      this.$log({
        name: 'SplitError',
        properties: {
          authorizationKey,
          userId,
          reason: `error when retrieving treatment for ${splitName}`,
          message: error.message,
          error,
        },
      })

      return getFallbackTreatment(splitTreatments[splitName])
    }
  },
}

function getFallbackTreatment(splitTreatment) {
  return splitTreatment.fallback || ''
}
