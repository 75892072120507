import Vue from 'vue'
import { defaultParams, couponMaxAge, orderIdCookieMaxAge, couponErrorMessages } from '@/utils/constants'

export const state = () => ({
  order: {},
  issues: [],
  shippingOptions: [],
  shippingFolder: '',
  paymentMethod: '',
  autoReorderDaysInterval: '',
  nextOrderDate: '',
  autoReorderId: '',
  autoReorderStatus: '',
  updateOrderPending: [],
  updateOrderActive: {},
})

export const mutations = {
  SET_ORDER(state, order) {
    Vue.set(state, 'order', order)
    Vue.set(state, 'shippingFolder', order.shipFolder)
  },
  SET_ISSUES(state, issues) {
    Vue.set(state, 'issues', issues)
  },
  SET_SHIPPING_OPTIONS(state, shippingOptions) {
    Vue.set(state, 'shippingOptions', shippingOptions)
  },
  SET_SHIPPING_FOLDER(state, shippingFolder) {
    Vue.set(state, 'shippingFolder', shippingFolder)
  },
  SET_PAYMENT_METHOD(state, paymentMethod) {
    Vue.set(state, 'paymentMethod', paymentMethod)
  },
  RESET_ORDER(state, orderId) {
    Vue.set(state, 'order', {})
    Vue.set(state, 'issues', [])
    Vue.set(state, 'shippingOptions', [])
    Vue.set(state, 'paymentMethod', '')
    Vue.set(state, 'autoReorderStatus', '')
    if (!orderId) {
      Vue.set(state, 'shippingFolder', '')
    }
  },
  SET_AUTO_REORDER_DAYS_INTERVAL(state, autoReorderDaysInterval) {
    Vue.set(state, 'autoReorderDaysInterval', autoReorderDaysInterval)
  },
  SET_NEXT_ORDER_DATE(state, nextOrderDate) {
    Vue.set(state, 'nextOrderDate', nextOrderDate)
  },
  SET_AUTO_REORDER_ID(state, autoReorderId) {
    Vue.set(state, 'autoReorderId', autoReorderId)
  },
  SET_AUTO_REORDER_STATUS(state, autoReorderStatus) {
    Vue.set(state, 'autoReorderStatus', autoReorderStatus)
  },
  ADD_TO_UPDATE_ORDER_PENDING(state, job) {
    Vue.set(state, 'updateOrderPending', [...state.updateOrderPending, job])
  },
  SET_UPDATE_ORDER_ACTIVE(state, job) {
    Vue.set(state, 'updateOrderActive', job)
  },
  SHIFT_UPDATE_ORDER_PENDING(state) {
    const pendingCopy = [...state.updateOrderPending]
    pendingCopy.shift()
    Vue.set(state, 'updateOrderPending', pendingCopy)
  },
}

export const actions = {
  selectShipping({ commit }, shippingFolder) {
    commit('SET_SHIPPING_FOLDER', shippingFolder)
    this.$cookies.set('shippingFolder', shippingFolder, { maxAge: orderIdCookieMaxAge })
  },
  resetOrder({ commit }, orderId = false) {
    commit('RESET_ORDER', orderId)

    this.$cookies.remove('orderId')
    this.$cookies.remove('deliveryAddressId')
    this.$cookies.remove('billingAddressId')
    this.$cookies.remove('shippingFolder')
  },
  async createOrder({ commit, rootState, dispatch }) {
    const { lines } = rootState.basket

    commit('REMOVE_ERROR', 'createOrder', { root: true })
    commit('ADD_LOADING', 'createOrder', { root: true })

    if (lines.length === 0) {
      return
    }

    const serializedLines = lines.map(({ qty, productId, options }) => ({
      qty,
      productId,
      options,
    }))
    const { deliveryAddressId, billingAddressId } = rootState.addresses
    const shipFolder = this.$cookies.get('shippingFolder')
    const couponCode = rootState.couponCode
    const newOrder = {
      lines: serializedLines,
      ...(deliveryAddressId && { deliveryAddressId }),
      ...(billingAddressId && { billingAddressId }),
      ...(shipFolder && { shipFolder }),
    }

    try {
      const response = await this.$axios.post(
        '/order',
        { ...newOrder, ...(couponCode && { couponCode }) },
        { params: { ...defaultParams } },
      )
      const { data: orderOld } = response.data
      const { order: orderNew, issues = [] } = response.data.data
      const order = orderNew || orderOld

      commit('SET_ORDER', order)
      commit('SET_ISSUES', issues)
      await dispatch('basket/loadBasket', order.lines, { root: true })
      this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
    } catch (error) {
      try {
        const response = await this.$axios.post('/order', newOrder, { params: { ...defaultParams } })
        const { data: orderOld } = response.data
        const { order: orderNew, issues = [] } = response.data.data
        const order = orderNew || orderOld

        commit('SET_ORDER', order)
        commit('SET_ISSUES', issues)
        await dispatch('basket/loadBasket', order.lines, { root: true })
        this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
      } catch (error) {
        commit('ADD_ERROR', { id: 'createOrder', error }, { root: true })
      }
    }

    commit('REMOVE_LOADING', 'createOrder', { root: true })
  },
  async createOrderFromAutoReorder({ commit, dispatch, rootState }, autoReorderId) {
    commit('REMOVE_ERROR', 'createOrderFromAutoReorder', { root: true })
    commit('ADD_LOADING', 'createOrderFromAutoReorder', { root: true })

    const lines = []
    let deliveryAddressId
    try {
      const response = await this.$axios.get(`/payments/autoreorders/${autoReorderId}`, {
        params: { ...defaultParams },
      })
      const { lines: retrievedLines, deliveryAddress } = response.data.data
      deliveryAddressId = deliveryAddress?.id
      lines.push(...retrievedLines)
    } catch (error) {
      commit('ADD_ERROR', { id: 'createOrderFromAutoReorder', error }, { root: true })
      return
    }

    if (lines.length === 0) {
      const error = new Error('No basket lines when creating order from Auto Reorder')
      commit('ADD_ERROR', { id: 'createOrderFromAutoReorder', error }, { root: true })
      return
    }

    const serializedLines = lines.map(({ qty, productId, options }) => ({
      qty,
      productId,
      options,
    }))

    const couponCode = rootState.couponCode

    const newOrder = {
      lines: serializedLines,
      ...(deliveryAddressId && { deliveryAddress: deliveryAddressId }),
    }

    try {
      const response = await this.$axios.post(
        '/order',
        { ...newOrder, ...(couponCode && { couponCode }) },
        { params: { ...defaultParams } },
      )
      const { data: orderOld } = response.data
      const { order: orderNew, issues = [] } = response.data.data
      const order = orderNew || orderOld

      commit('SET_ORDER', order)
      commit('SET_ISSUES', issues)
      await dispatch('basket/loadBasket', order.lines, { root: true })
      this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
    } catch (error) {
      try {
        const response = await this.$axios.post('/order', newOrder, { params: { ...defaultParams } })
        const { data: orderOld } = response.data
        const { order: orderNew, issues = [] } = response.data.data
        const order = orderNew || orderOld

        commit('SET_ORDER', order)
        commit('SET_ISSUES', issues)
        await dispatch('basket/loadBasket', order.lines, { root: true })
        this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
      } catch (error) {
        commit('ADD_ERROR', { id: 'createOrderFromAutoReorder', error }, { root: true })
      }
    }

    commit('REMOVE_LOADING', 'createOrderFromAutoReorder', { root: true })
  },
  async getOrder({ commit, dispatch }, orderId) {
    commit('REMOVE_ERROR', 'getOrder', { root: true })
    commit('ADD_LOADING', 'getOrder', { root: true })

    try {
      const response = await this.$axios.get(`/order/${orderId}`, { params: { ...defaultParams } })
      const { data: orderOld } = response.data
      const { order: orderNew, issues = [] } = response.data.data
      const order = orderNew || orderOld

      if (order.couponCode) {
        commit('SET_COUPON_DETAILS', { code: order.couponCode }, { root: true })
      }

      commit('SET_ORDER', order)
      commit('SET_ISSUES', issues)
      await dispatch('basket/loadBasket', order.lines, { root: true })
      this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
    } catch (error) {
      if (error?.response?.status === 400) {
        dispatch('convertOrderToLimited', orderId)
      }

      commit('ADD_ERROR', { id: 'getOrder', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'getOrder', { root: true })
  },
  async updateOrder({ commit, rootState, state, dispatch }, { orderContext = '' }) {
    commit('REMOVE_ERROR', 'updateOrder', { root: true })
    commit('ADD_LOADING', 'updateOrder', { root: true })

    const { lines } = rootState.basket
    const { orderId } = state.order
    const serializedLines = lines.map(({ qty, productId, options }) => ({
      qty,
      productId,
      options,
    }))
    const { deliveryAddressId, billingAddressId } = rootState.addresses
    const { shippingFolder: shipFolder } = state
    const couponCode = rootState.couponCode
    const updatedOrder = {
      lines: serializedLines,
      deliveryAddress: deliveryAddressId,
      billingAddress: billingAddressId,
      shipFolder,
    }

    if (!orderId) {
      return
    }

    const job = {
      data: {
        updatedOrder,
        couponCode,
        orderId,
        orderContext,
      },
      async handler(orderData) {
        await dispatch('makeUpdateOrderRequests', orderData)
      },
    }
    await dispatch('pushItemToUpdateOrderQueue', job)
    commit('REMOVE_LOADING', 'updateOrder', { root: true })
  },
  async createReorder({ commit, dispatch, rootState }, orderId) {
    commit('REMOVE_ERROR', 'createReorder', { root: true })
    commit('ADD_LOADING', 'createReorder', { root: true })

    const couponCode = rootState.couponCode
    try {
      const response = await this.$axios.post(
        `/order/${orderId}/reorder`,
        { ...(couponCode && { couponCode }) },
        { params: { ...defaultParams } },
      )
      const { data: orderOld } = response.data
      const { order: orderNew, issues = [] } = response.data.data
      const order = orderNew || orderOld

      if (order.couponCode) {
        commit('SET_COUPON_DETAILS', { code: order.couponCode }, { root: true })
      }

      commit('SET_ISSUES', issues)
      commit('SET_ORDER', order)
      await dispatch('basket/loadBasket', order.lines, { root: true })
      this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
    } catch (error) {
      if (error?.response?.status === 400) {
        dispatch('convertOrderToLimited', orderId)
      }

      commit('ADD_ERROR', { id: 'createReorder', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'createReorder', { root: true })
  },
  async loadUnpaidOrder({ dispatch, rootState }, { orderId, sendUserToPayments = false }) {
    dispatch('resetOrder')
    await dispatch('getOrder', orderId)
    await dispatch('matchDeliveryAddress')

    if (!rootState.errors?.getOrder) {
      await dispatch('getShippingOptions')
      dispatch('handleOrderIssues', { sendUserToPayments })
    }
  },
  async loadReorderOrder({ dispatch, rootState, state }, { orderId, sendUserToPayments = false }) {
    dispatch('resetOrder', orderId)
    await dispatch('createReorder', orderId)
    await dispatch('matchDeliveryAddress')
    const { issues } = state
    if (!rootState.errors?.createReorder || issues.length) {
      await dispatch('getShippingOptions')
      dispatch('handleOrderIssues', { sendUserToPayments })
    }
  },
  async copyAutoReorderOrder({ commit, dispatch, rootState }, { autoReorderId, sendUserToPayments = false }) {
    dispatch('resetOrder')
    commit('ADD_LOADING', 'copyAutoReorderOrder', { root: true })
    await dispatch('createOrderFromAutoReorder', autoReorderId) // copy createReorder, but get order lines from AR
    await dispatch('matchDeliveryAddress')
    if (!rootState.errors?.createOrderFromAutoReorder) {
      await dispatch('getShippingOptions')
      dispatch('handleOrderIssues', { sendUserToPayments })
    }
    commit('REMOVE_LOADING', 'copyAutoReorderOrder', { root: true })
  },
  convertOrderToLimited({ rootState, commit, dispatch }, orderId) {
    const { orders } = rootState.account
    const { lines = [] } = orders.find((order) => order.orderId === orderId) || {}
    const issues = lines.map((line) => ({ ...line, reason: 'limited' }))

    dispatch('resetOrder')
    commit('SET_ISSUES', issues)
    dispatch('basket/emptyBasket', null, { root: true })
  },
  handleOrderIssues({ state }, { sendUserToPayments }) {
    const { order, issues, shippingOptions } = state

    if (order?.lines?.length === 0 || issues.length > 0) {
      this.$router.push({
        path: this.$getLink('/basket'),
        name: `${this.$i18n.locale}/basket`,
        params: { showReorderError: true },
      })
    } else {
      sendUserToPayments && shippingOptions.length !== 0
        ? this.$router.push({
            path: this.$getLink('/payment'),
            name: `${this.$i18n.locale}/payment`,
            params: { reOrder: true },
          })
        : this.$router.push(this.$getLink('/delivery'))
    }
  },
  async matchDeliveryAddress({ state, rootState, dispatch }) {
    const deliveryAddress = state.order?.deliveryAddress
    await dispatch('addresses/getAllAddresses', null, { root: true })
    const { addresses } = rootState.addresses
    const deliveryAddressMatch = addresses.find(
      (address) =>
        address?.add1 === deliveryAddress?.add1 &&
        address?.add2 === deliveryAddress?.add2 &&
        address?.postcode === deliveryAddress?.postcode &&
        address?.countryId === deliveryAddress?.countryId,
    )

    if (deliveryAddressMatch?.id) {
      dispatch('addresses/setDeliveryAddressId', deliveryAddressMatch.id, { root: true })
    }
  },
  resetOrderIssues({ commit }) {
    commit('SET_ISSUES', [])
  },
  async getShippingOptions({ commit, dispatch, rootState, state }) {
    const { orderId } = state.order

    if (!orderId) {
      return
    }

    commit('REMOVE_ERROR', 'shipping', { root: true })
    commit('ADD_LOADING', 'shipping', { root: true })

    try {
      const response = await this.$axios.get(`/order/${orderId}/shipping`, { params: { ...defaultParams } })
      const { data: shippingOptions } = response.data
      const selectedShippingMethodExist = shippingOptions.some(
        (shippingOption) => shippingOption.folder === state.shippingFolder,
      )

      if (!selectedShippingMethodExist && shippingOptions.length) {
        await dispatch('getConfig', null, { root: true })
        const defaultOption = shippingOptions.find(
          (shippingOption) => shippingOption.folder === rootState.defaultShippingMethod?.folder,
        )
        const shippingOptionToSet = defaultOption || shippingOptions[0]
        commit('SET_SHIPPING_FOLDER', shippingOptionToSet.folder)
        await dispatch('updateOrder', { orderContext: 'store/order getShippingOptions' })
      }

      commit('SET_SHIPPING_OPTIONS', shippingOptions)
    } catch (error) {
      commit('ADD_ERROR', { id: 'shipping', error }, { root: true })
    }

    commit('REMOVE_LOADING', 'shipping', { root: true })
  },
  setPaymentMethod({ commit }, paymentMethod) {
    commit('SET_PAYMENT_METHOD', paymentMethod)
  },
  setAutoReorderDaysInterval({ commit }, autoReorderDaysInterval) {
    commit('SET_AUTO_REORDER_DAYS_INTERVAL', autoReorderDaysInterval)
  },
  setNextOrderDate({ commit }, nextOrderDate) {
    commit('SET_NEXT_ORDER_DATE', nextOrderDate)
  },
  setAutoReorderId({ commit }, autoReorderId) {
    commit('SET_AUTO_REORDER_ID', autoReorderId)
  },
  setAutoReorderStatus({ commit }, autoReorderStatus) {
    commit('SET_AUTO_REORDER_STATUS', autoReorderStatus)
  },
  async makeUpdateOrderRequests({ commit, dispatch }, orderData) {
    try {
      const response = await dispatch('sendUpdateOrderRequest', {
        updatedOrder: { ...orderData.updatedOrder, ...(orderData.couponCode && { couponCode: orderData.couponCode }) },
        orderId: orderData.orderId,
        orderContext: orderData.orderContext,
      })
      const { data: orderOld } = response.data
      const { order: orderNew, issues = [] } = response.data.data
      const order = orderNew || orderOld

      commit('SET_ORDER', order)
      commit('SET_ISSUES', issues)
      await dispatch('basket/loadBasket', order.lines, { root: true })

      if (orderData.couponCode) {
        commit('SET_COUPON_DETAILS', { code: order.couponCode }, { root: true })
        this.$cookies.set('couponCode', orderData.couponCode, { maxAge: couponMaxAge })
      }

      this.$cookies.set('orderId', order.orderId, { maxAge: orderIdCookieMaxAge })
    } catch (error) {
      // Only set coupon error if the errorCode is a coupon error.
      if (orderData.couponCode && couponErrorMessages[error.response?.data?.errorCode]) {
        const errorMessage = couponErrorMessages[error.response.data.errorCode]

        commit('SET_COUPON_ERROR', { couponCode: orderData.couponCode, errorMessage }, { root: true })
      }

      if (error?.response?.status === 410) {
        dispatch('basket/emptyBasket', null, { root: true })
        commit('RESET_ORDER', false)
        this.$cookies.remove('orderId')
      } else if (error?.response?.status !== 401) {
        try {
          const response = await dispatch('sendUpdateOrderRequest', {
            updatedOrder: orderData.updatedOrder,
            orderId: orderData.orderId,
            orderContext: orderData.orderContext,
          })
          const { data: orderOld } = response.data
          const { order: orderNew, issues = [] } = response.data.data
          const order = orderNew || orderOld

          commit('SET_ORDER', order)
          commit('SET_ISSUES', issues)
          await dispatch('basket/loadBasket', order.lines, { root: true })
        } catch (error) {
          commit('ADD_ERROR', { id: 'updateOrder', error }, { root: true })
        }
      }
    }
  },
  sendUpdateOrderRequest(_context, orderRequestData) {
    const headers = {
      'x-api-order-context': orderRequestData.orderContext,
    }
    return this.$axios.put(
      `/order/${orderRequestData.orderId}`,
      { ...orderRequestData.updatedOrder },
      { params: { ...defaultParams }, headers },
    )
  },
  pushItemToUpdateOrderQueue({ dispatch }, job) {
    return new Promise((resolve) => {
      dispatch('addUpdateOrderPendingJob', {
        data: job.data,
        async handler(data) {
          await job.handler(data)
          resolve()
        },
      })
    })
  },
  addUpdateOrderPendingJob({ commit, state, dispatch }, job) {
    commit('ADD_TO_UPDATE_ORDER_PENDING', job)
    if (Object.keys(state.updateOrderActive).length === 0) {
      dispatch('startNextUpdateOrderJob')
    }
  },
  async startNextUpdateOrderJob({ commit, state, dispatch }) {
    if (state.updateOrderPending.length > 0) {
      commit('SET_UPDATE_ORDER_ACTIVE', state.updateOrderPending[0])
      commit('SHIFT_UPDATE_ORDER_PENDING')
      await state.updateOrderActive.handler(state.updateOrderActive.data)
      dispatch('startNextUpdateOrderJob')
    } else {
      commit('SET_UPDATE_ORDER_ACTIVE', {})
    }
  },
}
