// noinspection JSUnusedGlobalSymbols
export default ({ $log }) => {
  // Generic browser testing
  if (!window.navigator.cookieEnabled) {
    $log({
      name: 'cookies not enabled',
    })
  }

  // Generic browser testing
  if (!isLocalStorageAvailable()) {
    $log({
      name: 'local storage not enabled',
    })
  }

  function isLocalStorageAvailable() {
    const test = 'test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }
}
