import braintree from 'braintree-web'
import { defaultParams } from '@/utils/constants'

export default (context, inject) => {
  const pluginName = 'braintree'

  let brainTreeClient = null
  let dataCollectorInstance = null
  let clientPromise = null // Promise to track client creation
  let dataCollectorPromise = null // Promise to track data collector creation

  const createClient = () => {
    if (clientPromise) return clientPromise // Return existing promise if available

    clientPromise = (async () => {
      try {
        const response = await context.$axios.post('/payments/braintree/identity/client', {}, { params: defaultParams })
        const token = response.data.data.clientToken
        return await braintree.client.create({ authorization: token })
      } catch (error) {
        clientPromise = null // Reset promise on error
        throw error
      }
    })()

    return clientPromise
  }

  const initDataCollector = () => {
    if (dataCollectorPromise) return dataCollectorPromise // Return existing promise

    dataCollectorPromise = (async () => {
      try {
        const btClient = await createClient()
        return await braintree.dataCollector.create({ client: btClient })
      } catch (error) {
        dataCollectorPromise = null // Reset on error
        throw error
      }
    })()

    return dataCollectorPromise
  }

  const braintreePlugin = {
    async client() {
      try {
        brainTreeClient = await createClient()
        return brainTreeClient
      } catch (error) {
        console.error('Error initializing Braintree client:', error)
        throw error
      }
    },

    async deviceData() {
      try {
        dataCollectorInstance = await initDataCollector()
        return dataCollectorInstance.getDeviceData()
      } catch (error) {
        console.error('Error initializing Braintree data collector:', error)
        throw error
      }
    },
  }

  inject(pluginName, braintreePlugin)
}
